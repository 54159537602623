<template>
  <ContactFormBase
    :form-data="formData"
    :csrf="csrf"
    :route="route"
    :form-id="formId"
    :submit-text="submitText"
    :classes="classes"
    @reset="resetData"
  >
    <div class="uk-margin-small-bottom">
      <input v-model="formData.name" class="uk-input" name="name" type="text" placeholder="Your Name" required />
    </div>
    <div class="uk-margin-small-bottom">
      <input v-model="formData.email" class="uk-input" name="email" type="email" placeholder="Your Email" required />
    </div>
    <div class="uk-margin-small-bottom">
      <textarea
        v-model="formData.message"
        class="uk-textarea"
        name="message"
        :rows="rows"
        :placeholder="messagePlaceholder"
        required
      />
    </div>
  </ContactFormBase>
</template>

<script>
import ContactFormBase from './ContactFormBase.vue'

export default {
  name: 'ContactForm',
  components: {
    ContactFormBase,
  },
  props: {
    route: {
      type: String,
      default: '',
    },
    classes: {
      type: String,
      default: 'uk-margin-medium-top',
    },
    submitText: {
      type: String,
      default: 'Submit',
    },
    formId: {
      type: String,
      default: 'contact_t2',
    },
    csrf: {
      type: String,
      required: true,
    },
    rows: {
      type: Number,
      default: 5,
    },
    messagePlaceholder: {
      type: String,
      default: 'How can we help?',
    },
  },
  data() {
    return {
      formData: {
        name: '',
        email: '',
        message: '',
      },
    }
  },
  methods: {
    resetData() {
      this.formData = {
        name: '',
        email: '',
        message: '',
      }
    },
  },
}
</script>
